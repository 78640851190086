<script>
import {
  SuawMainContent,
  SuawForm,
  SuawHeading,
  SuawTextInput,
  SuawButton,
  SuawInputGroup,
  SuawLocationInput,
  SuawTabs,
  SuawSearchInput,
  SuawCheckbox,
  SuawParagraph
} from "@suaw/suaw-component-library";
import { required } from "vuelidate/lib/validators";
import { validateErrors, formFieldState, urlWithProtocol } from "../../../../utils/api/validationErrors";
import SeriesEditFormButtons from "./SeriesEditFormButtons.vue";
import * as SeriesApi from "../../api.js";

export default {
  name: "SeriesLocation",
  components: {
    SuawMainContent,
    SuawForm,
    SuawHeading,
    SuawTextInput,
    SuawButton,
    SuawInputGroup,
    SuawLocationInput,
    SuawTabs,
    SuawSearchInput,
    SuawCheckbox,
    SeriesEditFormButtons,
    SuawParagraph
  },
  props: {
    chapter: {
      type: Object,
      default: () => {}
    },
    series: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      activeTabIndex: 0,
      eventTypeTabs: [{ label: "In-Person" }, { label: "Online" }],
      seriesPhysicalLocationForm: {
        eventSeriesId: this.$route.params.seriesId,
        lat: undefined,
        lng: undefined,
        physicalAddress: "",
        physicalTitle: ""
      },
      seriesVirtualLocationForm: {
        eventSeriesId: this.$route.params.seriesId,
        virtualUrl: "",
        virtualMeetingId: "",
        virtualMeetingPassword: "",
        isGlobal: false
      }
    };
  },
  computed: {
    isGlobalOnlineChapter() {
      return this.chapter?.slug === "shutupandwriteonlineevents";
    },
    publishedEdit() {
      return this.series && !this.series.is_draft;
    },
    summaryHeading() {
      if (this.isGlobalOnlineChapter) {
        return this.publishedEdit ? "Edit Global Online Series" : "Create New Global Online Series";
      }
      return this.publishedEdit ? "Edit Series" : "Create New Series";
    },
    isGlobalDisabled() {
      // returns true if chapter is global online chapter or is not draft
      return this.isGlobalOnlineChapter || this.publishedEdit;
    }
  },
  created() {
    if (this.series) {
      if (this.series.is_virtual) {
        this.activeTabIndex = 1;
        this.seriesVirtualLocationForm.virtualUrl = this.series.venue_json.virtualUrl;
        this.seriesVirtualLocationForm.virtualMeetingId = this.series.venue_json.virtualMeetingId;
        this.seriesVirtualLocationForm.virtualMeetingPassword = this.series.venue_json.virtualMeetingPassword;
        this.seriesVirtualLocationForm.isGlobal = this.series.is_global;
      } else {
        this.activeTabIndex = 0;
        this.seriesPhysicalLocationForm.lat = this.series.place.lat;
        this.seriesPhysicalLocationForm.lng = this.series.place.lng;
        this.seriesPhysicalLocationForm.physicalAddress = this.series.venue_json.physicalAddress;
        this.seriesPhysicalLocationForm.physicalTitle = this.series.venue_json.physicalTitle;
      }
    }
  },
  validations: {
    // the search input will be required once it has isRequired and errorMessage prop
    seriesPhysicalLocationForm: {
      physicalTitle: { required },
      physicalAddress: { required }
    },
    seriesVirtualLocationForm: {
      virtualUrl: { required, urlWithProtocol }
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    onPlaceSelected(place) {
      this.seriesPhysicalLocationForm.physicalTitle = place.name;
      this.seriesPhysicalLocationForm.physicalAddress = place.formatted_address;
      this.seriesPhysicalLocationForm.lat = place.geometry.location.lat();
      this.seriesPhysicalLocationForm.lng = place.geometry.location.lng();
    },
    onPrev() {
      this.$emit("next-step", { step: "schedule-edit", seriesId: this.series.id });
    },
    async updateEventSeriesPhysicalLocation() {
      const { eventSeriesId, lat, lng, physicalAddress, physicalTitle } = this.seriesPhysicalLocationForm;
      const result = await SeriesApi.updateEventSeriesPhysicalLocation(eventSeriesId, lat, lng, physicalAddress, physicalTitle);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async updateEventSeriesVirtualLocation() {
      const { eventSeriesId, virtualUrl, virtualMeetingId, virtualMeetingPassword, isGlobal } = this.seriesVirtualLocationForm;
      const result = await SeriesApi.updateEventSeriesVirtualLocation(eventSeriesId, virtualUrl, virtualMeetingId, virtualMeetingPassword, isGlobal);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSeriesLocationSubmit() {
      const isVirtual = this.activeTabIndex === 1 || this.isGlobalOnlineChapter;
      const validation = isVirtual ? this.$v.seriesVirtualLocationForm : this.$v.seriesPhysicalLocationForm;
      const updateMethod = isVirtual ? this.updateEventSeriesVirtualLocation : this.updateEventSeriesPhysicalLocation;
      const errorMessage = isVirtual ? "The series virtual location form is invalid." : "The series physical location form is invalid.";
      if (this.isGlobalOnlineChapter) {
        this.seriesVirtualLocationForm.isGlobal = true;
      }
      validation.$touch();
      if (validation.$invalid) {
        this.$root.$emit("universal-error-message", errorMessage);
        return;
      }
      const updateResult = await updateMethod();
      if (updateResult.success) {
        this.$emit("next-step", { step: "description" });
      }
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawHeading level="2" :content="summaryHeading" />
    <SuawHeading v-if="!isGlobalOnlineChapter" level="4" content="Event Type" />
    <SuawParagraph v-if="publishedEdit && !isGlobalOnlineChapter" size="small" text="Event Type of a published series can't be changed." alignment="left" />
    <SuawForm v-if="isGlobalOnlineChapter">
      <template #form>
        <SuawHeading level="4" content="Video Conferencing" />
        <SuawTextInput
          id="eventMeetingUrl"
          v-model="seriesVirtualLocationForm.virtualUrl"
          type="text"
          no-label
          is-required
          placeholder="Meeting URL"
          :state="formFieldState($v, 'seriesVirtualLocationForm', 'virtualUrl')"
          :error-message="validateErrors($v.seriesVirtualLocationForm.virtualUrl, 'Meeting URL')"
          @blur="$v.seriesVirtualLocationForm.virtualUrl.$touch()"
        />
        <SuawTextInput id="virtual-meeting-id" v-model="seriesVirtualLocationForm.virtualMeetingId" type="text" no-label placeholder="Meeting ID (Optional)" />
        <SuawTextInput id="virtual-meeting-password" v-model="seriesVirtualLocationForm.virtualMeetingPassword" type="text" no-label placeholder="Passcode (Optional)" />
      </template>
    </SuawForm>
    <SuawTabs v-else v-model="activeTabIndex" :tabs="eventTypeTabs" tabs-type="buttons" :disabled="publishedEdit">
      <template #tab-0>
        <SuawForm>
          <template #form>
            <SuawHeading level="4" content="Location" />
            <SuawSearchInput
              v-model="seriesPhysicalLocationForm.physicalAddress"
              placeholder="Venue"
              :use-google="true"
              :show-icon="true"
              :is-required="true"
              :state="formFieldState($v, 'seriesPhysicalLocationForm', 'physicalAddress')"
              :error-message="validateErrors($v.seriesPhysicalLocationForm.physicalAddress, 'Venue address')"
              @blur="$v.seriesPhysicalLocationForm.physicalAddress.$touch()"
              @item-select="onPlaceSelected"
            />
            <SuawTextInput
              id="eventHostCity"
              v-model="seriesPhysicalLocationForm.physicalTitle"
              type="text"
              is-required
              label="Venue Display Name"
              placeholder="How the venue name will be displayed in your series."
              :state="formFieldState($v, 'seriesPhysicalLocationForm', 'physicalTitle')"
              :error-message="validateErrors($v.seriesPhysicalLocationForm.physicalTitle, 'Venue name')"
              @blur="$v.seriesPhysicalLocationForm.physicalTitle.$touch()"
            />
            <SuawLocationInput
              :location-name="seriesPhysicalLocationForm.physicalTitle"
              :location-address="seriesPhysicalLocationForm.physicalAddress"
              :lat="seriesPhysicalLocationForm.lat"
              :lng="seriesPhysicalLocationForm.lng"
              :zoom="15"
              :editable="true"
            />
          </template>
        </SuawForm>
      </template>
      <template #tab-1>
        <SuawForm>
          <template #form>
            <SuawHeading level="4" content="Video Conferencing" />
            <SuawTextInput
              id="eventMeetingUrl"
              v-model="seriesVirtualLocationForm.virtualUrl"
              type="text"
              no-label
              is-required
              placeholder="Meeting URL"
              :state="formFieldState($v, 'seriesVirtualLocationForm', 'virtualUrl')"
              :error-message="validateErrors($v.seriesVirtualLocationForm.virtualUrl, 'Meeting URL')"
              @blur="$v.seriesVirtualLocationForm.virtualUrl.$touch()"
            />
            <SuawTextInput id="virtual-meeting-id" v-model="seriesVirtualLocationForm.virtualMeetingId" type="text" no-label placeholder="Meeting ID (Optional)" />
            <SuawTextInput id="virtual-meeting-password" v-model="seriesVirtualLocationForm.virtualMeetingPassword" type="text" no-label placeholder="Passcode (Optional)" />
            <SuawParagraph v-if="publishedEdit" size="small" text="Global availability of a published series can't be changed." alignment="left" />
            <SuawCheckbox id="notifyChapter" v-model="seriesVirtualLocationForm.isGlobal" label="Make this online series available globally" :disabled="isGlobalDisabled" />
          </template>
        </SuawForm>
      </template>
    </SuawTabs>
    <SeriesEditFormButtons v-if="publishedEdit" @cancel="$emit('cancel')" @back="$emit('back')" @confirm="onSeriesLocationSubmit" />
    <SuawInputGroup v-else field-one-size="0" field-two-size="0" :centered="true">
      <SuawButton class="suaw-button--no-x-pd" size="large" type="ghost" icon-left="IconCircleChevronLeft" button-text="Prev" @click="onPrev" />
      <SuawButton class="suaw-button--no-x-pd" size="large" type="ghost" icon-right="IconCircleChevronRight" button-text="Next" @click="onSeriesLocationSubmit" />
    </SuawInputGroup>
  </SuawMainContent>
</template>
